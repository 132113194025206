import React, { useState, useContext } from 'react'
import FCard from '../../../../components/FCard'
import UnitsConversion from '../../../../components/UnitsConversion'
import Tooltip from '@mui/material/Tooltip'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { UserContext } from '../../../../components/UserContext'
import Alert from '@mui/material/Alert'

const BasicInfoCard = ({ response, setIsDeclaredValueModalOpen }) => {
  const [units, setUnits] = useState('imperial')
  const { user } = useContext(UserContext)
  const handleChange = (event) => {
    let newValue = event.target.value
    if (newValue === 'metric') {
      setUnits('metric')
    } else {
      setUnits('imperial')
    }
  }

  return (
    <FCard className={' my-2 '}>
      <FCard.Header>
        <div className="flex justify-between ">
          <span className="font-semibold text-gray-800"> Load basic information </span>
          <div
            className={
              '  flex h-fit w-fit whitespace-nowrap font-inter text-sm text-gray-500  '
            }
          >
            Shipment name :{''}
            <span>{response?.shipment.name}</span>
          </div>
        </div>
      </FCard.Header>
      <FCard.Body>
        <Tooltip title={'Units converter'} arrow placement="right">
          <div className="w-fit ">
            <UnitsConversion units={units} handleChange={handleChange} />
          </div>
        </Tooltip>
        <table className="mt-7 w-full table-auto  ">
          <thead>
            <tr className="border-collapse border border-slate-200 bg-slate-50 text-center ">
              <th className="border-collapse border border-slate-200">
                Length
                <span className="text-xs  font-medium  text-primary-500">
                  {' '}
                  ({units === 'metric' ? 'cm' : 'inches'})
                </span>
              </th>
              <th className="border-collapse border border-slate-200">
                Width
                <span className="text-xs font-medium  text-primary-500">
                  {' '}
                  ({units === 'metric' ? 'cm' : 'inches'})
                </span>
              </th>
              <th className="border-collapse border border-slate-200">
                Height
                <span className="text-xs font-medium  text-primary-500">
                  {' '}
                  ({units === 'metric' ? 'cm' : 'inches'})
                </span>
              </th>
              <th className="border-collapse border border-slate-200">
                Weight
                <span className="text-xs font-medium  text-primary-500">
                  {' '}
                  ({units === 'metric' ? 'kg' : 'pounds'})
                </span>
              </th>
              <th className="border-collapse border border-slate-200">
                Quantity{' '}
                <span className="text-xs font-medium  text-primary-500">(num)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className=" border-collapse border border-slate-200 text-center ">
              <td className="border-collapse border border-slate-200">
                {units === 'imperial'
                  ? response?.length
                  : (response?.length * 2.54).toFixed(2)}
              </td>
              <td className="border-collapse border border-slate-200">
                {units === 'imperial'
                  ? response?.width
                  : (response?.width * 2.54).toFixed(2)}
              </td>
              <td className="border-collapse border border-slate-200">
                {units === 'imperial'
                  ? response?.height
                  : (response?.height * 2.54).toFixed(2)}
              </td>
              <td className="border-collapse border border-slate-200">
                {units === 'imperial'
                  ? response?.weight
                  : (response?.weight / 2.20462).toFixed(2)}
              </td>
              <td className="border-collapse border border-slate-200">
                {response?.quantity}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="my-8  w-full   ">
          <div className="  ">
            <span className={'flex text-lg  font-semibold text-gray-900 '}>
              Load type : <br />
              <span className="my-auto ml-2 text-base font-normal text-gray-500">
                {response?.load_type}
              </span>
            </span>
          </div>
          <div className="  ">
            <span className={'flex text-lg  font-semibold text-gray-900'}>
              Commodity : <br />
              <span className="my-auto ml-2 text-base font-normal text-gray-500">
                {response?.commodity}
              </span>
            </span>
          </div>
          <div className="  ">
            <span className={'flex text-lg  font-semibold text-gray-900'}>
              Equipment type: <br />
              <span className="my-auto ml-2 text-base font-normal text-gray-500">
                {response?.equipment}
              </span>
            </span>
          </div>

          <div>
            <span className=" text-lg font-semibold  text-gray-900">
              Hazardous material :{' '}
            </span>
            {response?.goods_info.toLowerCase() === 'No'.toLowerCase() && (
              <span className="my-auto font-bold text-red-600">NO</span>
            )}
            {response?.goods_info.toLowerCase() === 'yes'.toLowerCase() && (
              <span className="my-auto font-bold text-green-600">Yes</span>
            )}
          </div>
          <div className="flex">
            <span className={'my-auto flex text-lg  font-semibold text-gray-900'}>
              Declared value :
              <br />
              <span className="my-auto ml-2 text-base font-normal text-gray-500">
                {response?.declared_value !== 0
                  ? response?.declared_value + ' ' + '$'
                  : 'Not assigned'}
              </span>
            </span>
            {response?.declared_value === 0 &&
              response?.customer?.username === user?.username && (
                <>
                  <Tooltip title={'Add declared value'} arrow placement="top">
                    <div className={'my-auto ml-2'}>
                      <AiOutlinePlusCircle
                        size={25}
                        className="cursor-pointer text-primary-500"
                        onClick={() => {
                          setIsDeclaredValueModalOpen(true)
                        }}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
          </div>
          <div>
            {response?.declared_value === 0 &&
              response?.customer?.username === user?.username && (
                <>
                  <div>
                    <Alert severity="error" className={'mt-1'}>
                      As a customer you have to add a declared value for this load to
                      continue with the process
                    </Alert>
                  </div>
                </>
              )}
          </div>
        </div>
      </FCard.Body>
    </FCard>
  )
}

export default BasicInfoCard
