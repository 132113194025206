import React from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineUserCircle, HiOutlineLocationMarker } from 'react-icons/hi'
import { MdOutlineDateRange } from 'react-icons/md'
import Status from '../../components/Status'
import LoadsCards from './LoadsCards'
import LoadStatusStat from './LoadStatusStat'

function UserDashboard({ response }) {
  const loads = response?.cards?.loads
  return (
    <div>
      <div className="flex flex-col px-5 pb-5">
        <div className="mt-2 grid grid-cols-1 gap-4   lg:grid-cols-3">
          <LoadsCards response={response} />
        </div>
      </div>
      {/* -------------------------------------------------------------------------------------- */}

      <div className="mx-5 flex flex-col gap-5 lg:grid lg:grid-cols-2 ">
        <div className="flex  w-full  flex-col  gap-2 ">
          <div className={'primary-shadow  border  bg-white  p-3'}>
            <div className={'flex items-center gap-2'}>
              <div className={'h-3 w-3 rounded-full bg-green-500'} />
              <span className={'font-semibold text-gray-700'}>Latest Active Loads</span>
            </div>
          </div>
          <div className="menu-wrap-y-dashboard h-80 overflow-hidden">
            {loads.map((load) => (
              <Link to={`/load-details/${load?.id}`} key={load.id}>
                <div
                  key={load.id}
                  className={
                    'primary-shadow  mb-5 border bg-white  p-3  hover:border-orange-500  hover:bg-gray-100 hover:transition-all hover:duration-500'
                  }
                >
                  <div className="flex justify-between   ">
                    <span className="p-5 pr-3  pt-2 text-xl font-semibold text-gray-800 ">
                      {load?.name + ' ' + 'load'}
                    </span>
                    <div className="">
                      <Status statusName={load?.status} />
                    </div>
                  </div>
                  <div className=" pb-10 lg:pb-0">
                    <div className="flex flex-row">
                      <div className={'flex flex-col gap-6 p-5 pr-3 pt-2'}>
                        <span className={' whitespace-nowrap '}>Pick-up</span>
                        <span className={' whitespace-nowrap '}>Drop-off</span>
                      </div>
                      <div className={'relative my-auto flex flex-row   text-sm  '}>
                        <div className="z-5  absolute  -top-1 -left-1 h-3 w-3 rounded-full bg-green-700 " />
                        <span className={'ml-0.5 border-r border-gray-300  py-8'}></span>
                        <span
                          className={' my-auto border-t border-gray-300 px-24 lg:px-52'}
                        ></span>
                        <div className="z-5 absolute top-14 -left-1 h-3 w-3  rounded-full bg-red-600" />
                        {/* pick-up information*/}
                        <div className="absolute bottom-10 ml-5 whitespace-nowrap lg:flex lg:flex-row ">
                          <div className="flex flex-row gap-1  ">
                            <span>
                              <MdOutlineDateRange
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="text-gray-900 ">
                              {load?.pick_up_date} at{' '}
                              {load?.pick_up_time ? load?.pick_up_time : 'N/A'}
                            </span>
                          </div>
                          <div className="flex flex-row gap-1 lg:ml-2">
                            <span>
                              <HiOutlineUserCircle
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="  text-gray-900">{load?.shipper}</span>
                          </div>
                          <div className="flex flex-row gap-1 lg:ml-2 ">
                            <span>
                              <HiOutlineLocationMarker
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="text-gray-900">
                              {load?.pick_up_location}
                            </span>
                          </div>
                        </div>
                        {/* drop-off information */}
                        <div className="absolute top-10 ml-5 whitespace-nowrap lg:flex lg:flex-row ">
                          {' '}
                          <div className="flex flex-row gap-1  ">
                            <span>
                              <MdOutlineDateRange
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="text-gray-900">
                              {load?.delivery_date} at{' '}
                              {load?.delivery_time ? load?.delivery_time : 'N/A'}
                            </span>
                          </div>
                          <div className="flex flex-row gap-1 lg:ml-2 ">
                            <span>
                              <HiOutlineUserCircle
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="text-gray-900">{load?.consignee}</span>
                          </div>
                          <div className="flex flex-row gap-1  lg:ml-2 ">
                            <span>
                              <HiOutlineLocationMarker
                                size={18}
                                className={'text-slate-600'}
                              />
                            </span>
                            <span className="text-gray-900">{load?.destination}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 pb-3 lg:mt-5">
                      {' '}
                      <span className="  px-5 text-sm text-gray-900">
                        Dispatcher : {load?.dispatcher}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* ----------------------------Chart Section------------------------------------------- */}
        <LoadStatusStat height={300} response={response} />
      </div>
    </div>
  )
}

export default UserDashboard
