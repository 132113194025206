import React, { useState, useEffect, useContext } from 'react'
import { FaUser, FaFilePdf, FaUpload } from 'react-icons/fa'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { axiosInstance } from '../../../axios/requister'
import FIconWrapper from '../../../components/FIconWrapper'
import FButton from '../../../components/FButton'
import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import Status from '../../../components/Status'
import { useCustomAxios } from '../../../Hooks/useAxios'
import { UserContext } from '../../../components/UserContext'

const DocumentsTab = ({ loadId, loadName, userInfo, status }) => {
  const [fileList, setFileList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [showCarrierCard, setShowCarrierCard] = useState(false)
  const [showCustomerCard, setShowCustomerCard] = useState(false)
  const [showBol, setShowBol] = useState(false)
  const [showCustomerBill, setShowCustomerBill] = useState(false)
  const [showCarrierBill, setShowCarrierBill] = useState(false)
  const [did_carrier_agree, setDid_carrier_agree] = useState(false)
  const [did_customer_agree, setDid_customer_agree] = useState(false)
  const [isLoadStatus, setIsLoadStatus] = useState(false)
  const getNextPage = () => setPage(page + 1)
  const getPreviousPage = () => setPage(page - 1)
  const { user } = useContext(UserContext)

  const AgreementDocsResponse = useCustomAxios({
    url:
      user?.selected_role === 'manager'
        ? `/manager/billing/?load=${loadId}`
        : `/docs/billing/?load=${loadId}`,
    method: 'GET',
  })
  const BillsResponse = useCustomAxios({
    url: `/shipment/load/billings/?load_id=${loadId}`,
    method: 'GET',
  })

  console.log('NEW BILL', BillsResponse?.response?.bills.length)

  useEffect(() => {
    if (AgreementDocsResponse?.response?.doc_type === 'dispatcher_docs') {
      setShowBol(true)
      setShowCarrierCard(true)
      setShowCustomerCard(true)
    } else if (AgreementDocsResponse?.response?.doc_type === 'customer_docs') {
      setShowBol(true)
      setShowCustomerCard(true)
    } else if (AgreementDocsResponse?.response?.doc_type === 'carrier_docs') {
      setShowBol(true)
      setShowCarrierCard(true)
    } else if (AgreementDocsResponse?.response?.doc_type === 'bol') {
      setShowBol(true)
    }

    if (BillsResponse.response?.doc_type === 'customer_docs' && BillsResponse?.response?.bills?.length !== 0) {
      setShowCustomerBill(true)
    } else if (BillsResponse.response?.doc_type === 'carrier_docs'&& BillsResponse?.response?.bills?.length !== 0) {
      setShowCarrierBill(true)
    } else if (BillsResponse.response?.doc_type === 'dispatcher_docs'&& BillsResponse?.response?.bills?.length !== 0) {
      setShowCarrierBill(true)
      setShowCustomerBill(true)
    }
  }, [AgreementDocsResponse, BillsResponse])

  const getFiles = () => {
    let getFileEndpoint
    if (userInfo?.response?.selected_role === 'manager') {
      getFileEndpoint = `manager/file/?load=${loadId}&page=${page}`
    } else {
      getFileEndpoint = `docs/file/?load=${loadId}&page=${page}`
    }
    setIsLoading(true)
    axiosInstance
      .get(getFileEndpoint)
      .then((res) => {
        setFileList(res.data.results)
        setCount(res.data.count)
      })
      .catch(() => {
        toast.error('Error while fetching files')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getFiles()
  }, [page])

  const totalNumberOfPages = Math.ceil(count / 5)

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0] // get the first file
    const fileSizeInMB = uploadedFile.size / (1024 * 1024)

    const formData = new FormData()
    formData.append('uploaded_file', uploadedFile)
    formData.append('name', uploadedFile.name)
    formData.append('uploaded_by', userInfo?.response?.id)
    formData.append('load', loadId)
    formData.append('size', fileSizeInMB.toFixed(2))
    const postFiles = () => {
      setIsLoading(true)
      axiosInstance
        .post('docs/file/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        .then(() => {
          setIsLoading(false)
          toast.success('File uploaded successfully')
        })
        .catch((err) => {
          setIsLoading(false)
          if (err.response?.status === 409) {
            toast.error('File already exists')
          } else toast.error('Error while uploading file')
        })
        .finally(() => {
          getFiles()
        })
    }

    if (fileSizeInMB > 5) {
      toast.error('File size should be less than 5MB')
    } else {
      postFiles()
    }
  }

  useEffect(() => {
    let rcEndpoint
    if (userInfo?.response?.selected_role === 'manager') {
      rcEndpoint = `manager/validate-rc/?load=${loadId}`
    } else {
      rcEndpoint = `docs/validate-rc/?load=${loadId}`
    }

    setIsLoadStatus(true)
    axiosInstance
      .get(rcEndpoint)
      .then((res) => {
        setIsLoadStatus(false)
        setDid_carrier_agree(res?.data?.did_carrier_agree)
        setDid_customer_agree(res?.data?.did_customer_agree)
      })
      .catch(() => {
        setIsLoadStatus(false)
      })
  }, [])

  const openRcCarrier = () => {
    window.open(`${window.location.origin}/rc-carrier?load=${loadId}`, '_blank')
  }
  const openRcCustomer = () => {
    window.open(`${window.location.origin}/rc-customer?load=${loadId}`, '_blank')
  }
  const openRBol = () => {
    window.open(`${window.location.origin}/rbol?load=${loadId}`, '_blank')
  }
  const openCustomerBill = () => {
    window.open(`${window.location.origin}/bill-doc?load=${loadId}`, '_blank')
  }
  const openCarrierBill = () => {
    window.open(`${window.location.origin}/bill-doc?load=${loadId}`, '_blank')
  }
  const openCustomerBillDispatcher = () => {
    window.open(
      `${window.location.origin}/customer-bill-dispatcher?load=${loadId}`,
      '_blank'
    )
  }

  const openCarrierBillDispatcher = () => {
    window.open(
      `${window.location.origin}/carrier-bill-dispatcher?load=${loadId}`,
      '_blank'
    )
  }

  if (AgreementDocsResponse.loading || isLoadStatus || isLoading) {
    return (
      <div className="mt-52 flex h-full w-full flex-col items-center justify-center">
        {' '}
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else {
    return (
      <div>

        {(status === 'Ready For Pickup' ||
          status === 'In Transit' ||
          status === 'Delivered') && (
          <div>
            <div className="p-5 text-xl font-semibold text-gray-700">
            Agreement Documents
            </div>
            <div className="mx-auto w-11/12 lg:flex ">
              {showCarrierCard && (
                <div className="overflow-hidden p-4 lg:w-1/3">
                  <div
                    className="transform cursor-pointer overflow-hidden rounded-sm bg-white shadow-md transition duration-300 hover:scale-95"
                    onClick={openRcCarrier}
                  >
                    <div className=" flex h-48 w-full items-center justify-center bg-gray-300 ">
                      <img
                        src="https://storage.googleapis.com/freight_static_files/RC-place-holder.PNG"
                        className=" h-full w-full object-cover"
                      />
                    </div>
                    <div className="border bg-white p-4">
                      <h3 className="flex  justify-between text-lg font-medium">
                        {"Carrier's Rate Confirmation"}
                        {isLoadStatus ? (
                          <ESpinner size={15} isVisible={true} />
                        ) : (
                          <>
                            {did_carrier_agree ? (
                              <Status statusName={'Approved'} />
                            ) : (
                              <Status statusName={'On Hold'} />
                            )}
                          </>
                        )}
                      </h3>
                      <p className="text-sm text-gray-600">
                        A legally binding document that confirms the transportation
                        agreement and helps to ensure both parties fulfill their
                        obligations.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {showCustomerCard && (
                <div className="p-4  lg:w-1/3">
                  <div
                    className="transform  cursor-pointer overflow-hidden rounded-sm bg-white shadow-md transition duration-300 hover:scale-95"
                    onClick={() => openRcCustomer()}
                  >
                    <div className="flex h-48 w-full items-center justify-center bg-gray-300 ">
                      <img
                        src="https://storage.googleapis.com/freight_static_files/RC-place-holder.PNG"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="border bg-white p-4">
                      <h3 className="flex  justify-between text-lg font-medium">
                        {"Customer's Rate Confirmation"}
                        {isLoadStatus ? (
                          <ESpinner size={15} isVisible={true} />
                        ) : (
                          <>
                            {did_customer_agree ? (
                              <Status statusName={'Approved'} />
                            ) : (
                              <Status statusName={'On Hold'} />
                            )}
                          </>
                        )}
                      </h3>
                      <p className="text-sm text-gray-600">
                        A legally binding document that confirms the transportation
                        agreement and helps to ensure both parties fulfill their
                        obligations.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {showBol && (
                <div className="p-4 lg:w-1/3 ">
                  <div
                    className="transform cursor-pointer overflow-hidden rounded-sm bg-white shadow-md transition duration-300 hover:scale-95"
                    onClick={openRBol}
                  >
                    <div className="flex h-48 w-full items-center justify-center bg-gray-300 ">
                      <img
                        src="https://storage.googleapis.com/freight_static_files/RC-place-holder.PNG"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="border bg-white p-4">
                      <h3 className="text-lg font-medium leading-tight">BOL</h3>
                      <p className="text-sm text-gray-600">
                        {' '}
                        A legal document that describes the details of a shipment and
                        serves as a receipt of shipment in the transportation of goods.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {!showCarrierCard && !showCustomerCard && !showBol && (
                <div
                  className={
                    '  mx-auto flex w-11/12 flex-col items-center justify-center  font-semibold'
                  }
                >
                  <img
                    src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
                    alt={'empty_state'}
                    width={100}
                    className={''}
                  />
                  <span className="mt-5 items-center text-center font-semibold text-gray-700">
                    No Agreement documents available for this load at the moment.
                  </span>
                </div>
              )}
            </div>

            <br />
            <hr />
            {/* ----------------------------------------BILLING DOCUMENTS ----------------------------------------------------------- */}

            <div className="p-5 text-xl font-semibold text-gray-700">
              Billing Documents
            </div>

            <div className="mx-auto w-11/12 lg:flex">
              {showCustomerBill && (
                <div className="p-4 lg:w-1/3 ">
                  <div
                    className="transform cursor-pointer overflow-hidden rounded-sm bg-white shadow-md transition duration-300 hover:scale-95"
                    onClick={
                      BillsResponse?.response?.doc_type === 'customer_docs'
                        ? openCustomerBill
                        : openCustomerBillDispatcher
                    }
                  >
                    <div className="flex h-48 w-full items-center justify-center bg-gray-300 ">
                      <img
                        src="https://storage.googleapis.com/freight_static_files/RC-place-holder.PNG"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="border bg-white p-4">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-medium leading-tight">
                          CUSTOMER BILL
                        </h3>
                        {console.log('BillsResponse', BillsResponse?.response?.doc_type)}

                        <Status
                          statusName={
                            BillsResponse?.response?.doc_type === 'dispatcher_docs'
                              ? BillsResponse?.response?.bills[0]?.status
                              : BillsResponse?.response?.bills?.status
                          }
                        />
                      </div>
                      <p className="text-sm text-gray-600">
                        {' '}
                        A legal document that describes the details of a shipment and
                        serves as a receipt of shipment in the transportation of goods.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {showCarrierBill && (
                <div className="p-4 lg:w-1/3 ">
                  <div
                    className="transform cursor-pointer overflow-hidden rounded-sm bg-white shadow-md transition duration-300 hover:scale-95"
                    onClick={
                      BillsResponse?.response?.doc_type === 'carrier_docs'
                        ? openCarrierBill
                        : openCarrierBillDispatcher
                    }
                  >
                    <div className="flex h-48 w-full items-center justify-center bg-gray-300 ">
                      <img
                        src="https://storage.googleapis.com/freight_static_files/RC-place-holder.PNG"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <div className="border bg-white p-4">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-medium leading-tight">
                          Carrier BILL
                        </h3>
                        <Status
                          statusName={
                            BillsResponse?.response?.doc_type === 'dispatcher_docs'
                              ? BillsResponse?.response?.bills[0]?.status
                              : BillsResponse?.response?.bills?.status
                          }
                        />
                      </div>
                      <p className="text-sm text-gray-600">
                        {' '}
                        A legal document that describes the details of a shipment and
                        serves as a receipt of shipment in the transportation of goods.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!showCustomerBill && !showCarrierBill   && (
              <div
                className={
                  '  mx-auto flex w-11/12 flex-col items-center justify-center  font-semibold'
                }
              >
                <img
                  src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
                  alt={'empty_state'}
                  width={100}
                  className={''}
                />
                <span className="mt-5 items-center text-center font-semibold text-gray-700">
                  No Billing documents available for this load at the moment.
                </span>
              </div>
            )}
          </div>
        )}
        {status !== 'In Transit' &&
          status !== 'Ready For Pickup' &&
          status !== 'Delivered' && (
            <>
            <div className="p-5 text-xl font-semibold text-gray-700">
              Agreement And Billing Documents
            </div>
            <div
              className={
                '  mx-auto flex w-11/12 flex-col items-center justify-center  font-semibold'
              }
            >
              <img
                src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
                alt={'empty_state'}
                width={100}
                className={''}
              />
              <span className="mt-5 items-center text-center font-semibold text-gray-700">
                No billing or agreement documents available for this load at the moment.
              </span>
            </div>
            </>
          )}

        <div className="flex justify-between p-5">
          <div className=" text-xl font-semibold text-gray-700">Other Documents</div>
          {userInfo?.response?.selected_role !== 'manager' && (
            <div>
              {' '}
              <label
                htmlFor="fileUpload"
                className="flex w-fit cursor-pointer gap-2 border border-gray-300 bg-white p-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <FaUpload />
                Upload Document
                <input
                  className="hidden"
                  id="fileUpload"
                  type="file"
                  onChange={handleFileUpload}
                  accept="application/pdf"
                />
              </label>
            </div>
          )}
        </div>

        <div className="mx-auto flex w-11/12 overflow-x-auto">
          <table className="scrollbar  scrollbar-hl-gray-300  scrollbar-thumb-gray-500 w-full table-auto">
            <thead className="text-base font-semibold text-gray-700">
              <tr className="  border border-gray-200 bg-gray-50    ">
                <th className="py-2 px-4 text-left ">Uploaded by</th>
                <th className="py-2 px-4 text-left ">File name</th>
                <th className="whitespace-nowrap py-2 px-4 text-left ">Uploaded at</th>
                <th className="py-2 px-4 text-left ">File size</th>
              </tr>
            </thead>

            {isLoading ? (
              <div className={' ml-96 flex items-center justify-center p-5'}>
                <ESpinner size={25} isVisible={true} />
              </div>
            ) : (
              fileList.map((file) => (
                <tbody key={file.name} className=" text-left text-sm">
                  <tr className=" border border-b bg-white">
                    <td className=" flex whitespace-nowrap px-4 py-2 font-semibold text-gray-500">
                      {' '}
                      <FaUser className="mr-2 " />
                      {file.uploaded_by}
                    </td>
                    <td className=" px-4 py-2 font-semibold text-gray-500">
                      <div
                        className="flex cursor-pointer whitespace-nowrap underline underline-offset-2"
                        onClick={() => ''}
                      >
                        <FaFilePdf className="mr-2  " size={20} />
                        <a href={file.url} target="_blank" rel="noreferrer">
                          {file.name.replace('_' + loadName, '')}
                        </a>
                      </div>
                    </td>

                    <td className=" flex whitespace-nowrap px-4 py-2 font-semibold text-gray-500">
                      {file.uploaded_at.slice(0, 10)}
                    </td>
                    <td className=" whitespace-nowrap px-4 py-2 font-semibold text-gray-500">
                      {file.size}
                      {' MB'}
                    </td>
                  </tr>
                </tbody>
              ))
            )}
          </table>
        </div>
        <div
          className={`${
            isLoading || totalNumberOfPages === 0
              ? ' hidden'
              : 'flex flex-col items-center justify-between rounded-b  py-5 lg:flex-row-reverse'
          }`}
        >
          <div className={'flex items-center justify-between rounded-b  px-5 '}>
            <div className={'mt-1 flex flex-row-reverse gap-2  '}>
              <FButton
                disabled={parseInt(page) === parseInt(totalNumberOfPages)}
                onClick={() => getNextPage()}
              >
                <FIconWrapper>
                  <span>Next</span>
                  <HiChevronRight />
                </FIconWrapper>
              </FButton>
              <FButton onClick={() => getPreviousPage()} disabled={parseInt(page) === 1}>
                <FIconWrapper>
                  <HiChevronLeft />
                  <span>Previous</span>
                </FIconWrapper>
              </FButton>
            </div>
          </div>
          <span
            className={`${isLoading ? ' hidden' : ' px-5 pt-5 text-sm lg:pt-0'}`}
          >{`page ${page} from ${totalNumberOfPages}`}</span>
        </div>
        {!isLoading && count === 0 && (
          <div className={'  flex  flex-col items-center justify-center   py-10'}>
            <img
              src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
              alt={'empty_state'}
              width={100}
              className={''}
            />
            <span className={'mt-5 font-semibold text-gray-700'}>No Documents Found</span>
            <span className={'mt-1 text-sm text-gray-600 '}>
              Upload documents to view them here
            </span>
          </div>
        )}
      </div>
    )
  }
}
export default DocumentsTab
