import FModal from '../../components/FModal'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import { useParams } from 'react-router-dom'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import { axiosInstance } from '../../axios/requister'
import React, { useState } from 'react'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'

const AddAdmin = ({ isCreateAdminModalOpen, setIsCreateAdminModalOpen, callback }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [serverError, setServerError] = useState('')
  const { id } = useParams()

  const loadUsername = (inputValue, callback) => {
    axiosInstance
      .post(`/shipment/filter-contact/`, {
        shipment: 'yes',
        keyword: inputValue,
      })
      .then((res) => {
        const options = res.data.results.map((user) => {
          return {
            value: user.contact.username,
            label: user.contact.username,
          }
        })
        callback(options)
      })
      .catch(() => {})
  }

  const Formik = useFormik({
    initialValues: {
      username: '',
    },
    onSubmit: (values) => {
      setServerError('')
      setIsLoaded(true)
      axiosInstance
        .post('/shipment/admin/', {
          shipment: id,
          admin: values.username.value,
        })
        .then(() => {
          setIsLoaded(false)
          setIsCreateAdminModalOpen(false)
          toast.success('Admin Added successfully')
          callback()
          Formik.resetForm(true)
        })
        .catch(() => {
          setServerError('Admin already exists')
          setIsLoaded(false)
        })
    },

    validate: (values) => {
      const errors = {}
      if (values.username === '') {
        errors.username = 'Username is required'
      }
      return errors
    },
  })

  return (
    <>
      <FModal
        callbackFunction={() => {
          Formik.resetForm()
          setServerError('')
        }}
        title={'Add Admis'}
        isOpen={isCreateAdminModalOpen}
        setIsOpen={() => {
          setIsCreateAdminModalOpen(false)
        }}
      >
        <form onSubmit={Formik.handleSubmit}>
          <FFormWrapper className={'w-12/12'}>
            <FLabel htmlFor="username">User name </FLabel>
            <AsyncSelect
              defaultOptions
              value={Formik.values.username}
              cacheOptions
              loadOptions={loadUsername}
              onChange={(e) => {
                Formik.setFieldValue('username', e)
              }}
              onBlur={() => {
                Formik.setFieldTouched('username', true)
              }}
              placeholder={'Select username'}
            />
            <small className={'text-left font-medium text-red-700'}>
              {Formik?.errors?.username}
            </small>
            {serverError !== '' && (
              <small className={'text-left font-medium text-red-700'}>
                {serverError}
              </small>
            )}
          </FFormWrapper>

          <div className={'flex w-full flex-row-reverse gap-1'}>
            <FButton type={'submit'} onClick={() => setIsCreateAdminModalOpen(true)}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Admin
                <ESpinner isVisible={isLoaded} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                Formik.resetForm()
                setIsCreateAdminModalOpen(false)
                setServerError('')
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default AddAdmin
