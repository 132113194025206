import { useNavigate } from 'react-router-dom'
import Status from '../../components/Status'
import { HiOutlineLocationMarker, HiOutlineUserCircle } from 'react-icons/hi'
import {
  MdOutlineDateRange,
  MdOutlineDelete,
  MdOutlinePendingActions,
} from 'react-icons/md'
import { useContext } from 'react'
import { UserContext } from './../../components/UserContext'
import { FcDisclaimer } from 'react-icons/fc'
import { axiosInstance } from '../../axios/requister'
import { toast } from 'react-toastify'
import { RiFolderInfoFill } from 'react-icons/ri'

const Instance = ({
  id,
  name,
  status,
  is_closed,
  shipper,
  pick_up_date,
  pick_up_location,
  consignee,
  delivery_date,
  delivery_time,
  pick_up_time,
  destination,
  dispatcher,
  color,
  customer,
  carrier,
  has_claim,
  page,
  isUnderInsured,
  setPage = () => {},
  isDraft = false,
  onDeleteDraft = () => {},
}) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const userName = user?.username

  const handleDeleteDraft = (event) => {
    event.stopPropagation()
    axiosInstance
      .delete(`/shipment/load/draft/${id}/`)
      .then(() => {
        toast.success('Load draft was deleted successfully')
        onDeleteDraft()
      })
      .catch((error) => {
        toast.error(error.message || 'Something went wrong')
      })
  }

  return (
    <div
      className="primary-shadow w-12/12 relative mx-auto mt-8 cursor-pointer 
      overflow-hidden border bg-white hover:border-orange-500  
      hover:bg-gray-100 hover:transition-all hover:duration-500"
    >
      <div
        onClick={(event) => {
          if (event.ctrlKey || event.metaKey) {
            // Open in new tab if Ctrl (or Command on Mac) is pressed
            window.open(isDraft ? `/drafts/${id}` : `/load-details/${id}`, '_blank')
          } else {
            // Otherwise, navigate within the same tab
            event.stopPropagation()
            setPage(page)
            navigate(isDraft ? `/drafts/${id}` : `/load-details/${id}`)
          }
        }}
      >
        {' '}
        <div className=" pb-24 lg:pb-0 relative">{/* create a patch for close load */}
          {is_closed && (
          <div className="absolute top-0 right-56">
            <span className="bg-red-500 text-white px-2 py-2  text-sm font-medium">
            Closed for you
            </span>
          </div>
          )}

          <div className={'flex flex-row justify-between p-5 pb-10 lg:pb-5'}>
            <div className="gap-2  lg:flex">
              <div className={'my-auto flex text-xl font-medium text-gray-800'}>
                {name + ' ' + ' load'}
              </div>
              <div>
                <div className="flex items-center gap-2">
                  {has_claim && !isDraft && (
                    <span className="my-auto flex bg-red-100 px-2 py-1 text-sm font-medium text-gray-800 whitespace-nowrap">
                      <FcDisclaimer size={20} /> Has Claim
                    </span>
                  )}
                  {isUnderInsured && (
                    <span className="my-auto flex bg-yellow-100 px-2 py-1 text-sm font-medium text-gray-800 whitespace-nowrap">
                      <RiFolderInfoFill
                        className="text-yellow-500
                      "
                        size={20}
                      />{' '}
                      Under Insured
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <Status statusName={status} color={color} />
              {isDraft && (
                <MdOutlineDelete
                  title="Delete Draft"
                  size={20}
                  className="ml-auto cursor-pointer text-gray-400 hover:text-gray-600"
                  onClick={handleDeleteDraft}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row">
            <div className={'flex flex-col gap-6 p-5 pr-3 pt-2'}>
              <span className={' whitespace-nowrap '}>Pick-up</span>
              <span className={' whitespace-nowrap '}>Drop-off</span>
            </div>
            <div className={'relative my-auto flex flex-row text-sm'}>
              <div className="z-5  absolute  -top-1 -left-1 h-3 w-3 rounded-full bg-green-700 " />
              <span className={'ml-0.5 border-r border-gray-300  py-8'}></span>
              <span className={' my-auto w-56 border-t border-gray-300 lg:w-96'}></span>
              <div className="z-5 absolute top-14 -left-1 h-3 w-3  rounded-full bg-red-600" />
              {/* pick-up information*/}
              <div className="absolute bottom-10 ml-5 whitespace-nowrap lg:flex lg:flex-row ">
                <div className="flex flex-row gap-1 ">
                  <span>
                    <MdOutlineDateRange size={18} className={'text-slate-600'} />
                  </span>
                  <span className="text-gray-900 ">
                    {pick_up_date || 'N/A'}{'  '}
                    {' at ' + pick_up_time || 'N/A'}
                  </span>
                </div>
                <div className="flex flex-row gap-1 lg:ml-2 ">
                  <span>
                    <HiOutlineUserCircle size={18} className={'text-slate-600'} />
                  </span>
                  <span className="  text-gray-900">{shipper || 'N/A'}</span>
                </div>

                <div className="flex flex-row gap-1 lg:ml-2 ">
                  <span>
                    <HiOutlineLocationMarker size={18} className={'text-slate-600'} />
                  </span>
                  <span className="text-gray-900">{pick_up_location || 'N/A'}</span>
                </div>
              </div>
              {/* drop-off information */}
              <div className="absolute top-10 ml-5 whitespace-nowrap lg:flex lg:flex-row ">
                {' '}
                <div className="flex flex-row gap-1 ">
                  <span>
                    <MdOutlineDateRange size={18} className={'text-slate-600'} />
                  </span>
                  <span className="text-gray-900">
                    {delivery_date || 'N/A'} {'  '}
                    {' at ' + delivery_time || 'N/A'}
                  </span>
                </div>
                <div className="flex flex-row gap-1 lg:ml-2 ">
                  <span>
                    <HiOutlineUserCircle size={18} className={'text-slate-600'} />
                  </span>
                  <span className="text-gray-900">{consignee || 'N/A'}</span>
                </div>
                <div className="flex flex-row gap-1  lg:ml-2 ">
                  <span>
                    <HiOutlineLocationMarker size={18} className={'text-slate-600'} />
                  </span>
                  <span className="text-gray-900">{destination || 'N/A'}</span>
                </div>
              </div>
            </div>

            <div className="absolute bottom-0  ml-5 flex flex-col gap-2  pb-5 text-sm lg:relative  lg:mt-2 lg:ml-40 lg:pb-3 lg:pr-5 ">
              <br />
              <span className="  text-gray-900">
                Dispatcher : {dispatcher ?? 'Not assigned '}
              </span>
              <div
                className={`${
                  (userName === dispatcher &&
                    user.selected_role === 'dispatcher' &&
                    status.toLowerCase() === 'Created'.toLowerCase()) ||
                  (userName === customer &&
                    user.selected_role === 'shipment party' &&
                    status.toLowerCase() == 'Awaiting Customer'.toLowerCase()) ||
                  (userName === dispatcher &&
                    user.selected_role === 'dispatcher' &&
                    status.toLowerCase() == 'Awaiting Dispatcher'.toLowerCase()) ||
                  (userName === dispatcher &&
                    user.selected_role === 'dispatcher' &&
                    status.toLowerCase() == 'Assigning Carrier'.toLowerCase()) ||
                  (userName === carrier &&
                    user.selected_role === 'carrier' &&
                    status.toLowerCase() == 'Awaiting Carrier'.toLowerCase()) ||
                  (userName === shipper &&
                    user.selected_role === 'shipment party' &&
                    status.toLowerCase() == 'Ready For Pickup'.toLowerCase()) ||
                  (userName === consignee &&
                    user.selected_role === 'shipment party' &&
                    status.toLowerCase() == 'In Transit'.toLowerCase())
                    ? 'flex w-fit gap-1 bg-yellow-50 px-2 text-yellow-600'
                    : 'hidden'
                }    `}
              >
                <span>
                  <MdOutlinePendingActions size={20} />
                </span>{' '}
                {!isDraft && 'Action required'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Instance
