import { useEffect, useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
// import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai'
import FButton from '../../components/FButton'
// import FIconWrapper from '../../components/FIconWrapper'
import ESpinner from '../../components/ESpinner'
import Instance from './Instance'
import { UserContext } from '../../components/UserContext'
import { usePersistedState } from '../../Hooks/usePersistedState'
import { axiosInstance } from '../../axios/requister'
import CustomPagination from '../../components/CustomPaginationStyle'

const ListLoad = () => {
  const { user } = useContext(UserContext)
  const [page, setPage] = usePersistedState('loadListPage', 1)
  const [searchValue, setSearchValue] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const [loading, setLoading] = useState(true)
  const topRef = useRef(null)

  const [countLoads, setCountLoads] = useState(0)
  const [filterValue, setFilterValue] = useState('')

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    setPage(1)
  }

  const handleFilter = (event) => {
    setFilterValue(event.target.value)
    setPage(1)
  }

  useEffect(() => {
    let endpoint
    if (user.selected_role === 'manager') {
      endpoint = `/manager/search-loads/?page=${page}`
    } else {
      endpoint = `/shipment/search-loads/?page=${page}`
    }

    let filter
    if (filterValue === 'my_loads') {
      filter = { my_loads: true }
    } else if (filterValue === 'has_claim') {
      filter = { has_claim: true }
    } else if (filterValue === 'no_claim') {
      filter = { has_claim: false }
    } else if (filterValue === 'closed_loads') {
      filter = { closed_loads: true }
    } else {
      filter = {}
    }

    axiosInstance
      .post(endpoint, {
        search: searchValue,
        filter,
      })
      .then((res) => {
        setFilteredResults(res.data.results)
        setCountLoads(res.data.count)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [page, searchValue, user.selected_role, filterValue])

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [page])
  console.log('page', page)

  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const totalNumberOfPages = Math.ceil(countLoads / 5)

  if (countLoads === 0 && !loading && searchValue === '') {
    return (
      // <Onboarding />
      <div>
        <div
          className={
            ' sticky top-0  z-10 flex  w-full justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
        >
          <span className={''}>Loads</span>
          <div
            className={`${
              user?.selected_role.toLowerCase() === 'carrier'.toLowerCase() ||
              user?.selected_role.toLowerCase() === 'manager'.toLowerCase()
                ? 'hidden'
                : 'block'
            }`}
          >
            <Link to={'/load/create'}>
              <FButton>
                <AiOutlinePlusCircle size={20} />
                <span className={'pl-1 text-sm font-medium'}>Create Load</span>{' '}
              </FButton>
            </Link>
          </div>
        </div>
        <div className={' flex  flex-col items-center justify-center   py-40'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'mt-5 font-semibold text-gray-700'}>No Data Found</span>
          {user.selected_role === 'manager' ? (
            <span className={'mt-1 text-sm text-gray-600 '}>
              Sorry, there are currently no loads available in your organization.
            </span>
          ) : (
            <span className={'mt-1 text-sm text-gray-600 '}>
              Sorry, there are currently no loads available. Please check back later or
              create one yourself!
            </span>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div div 
      ref={topRef}>
        <div
          className={
            'sticky top-0 z-10 flex w-full justify-between border-b bg-white p-3 ' +
            'text-left text-xl font-semibold text-gray-700'
          }
          
        >
          <span className={''}>Loads</span>
          <div
            className={`${
              user?.selected_role.toLowerCase() === 'carrier'.toLowerCase() ||
              user?.selected_role.toLowerCase() === 'manager'.toLowerCase()
                ? 'hidden'
                : 'block'
            }`}
          >
            <Link to={'/load/create'}>
              <FButton>
                <AiOutlinePlusCircle size={20} />
                <span className={'pl-1 text-sm font-medium'}>Create Load</span>{' '}
              </FButton>
            </Link>
          </div>
        </div>

        <div className="py-3" >
          <div className="mx-auto mb-2 flex overflow-hidden px-2 lg:px-10">
            <div className="relative flex w-full gap-5">
              <div className="w-10/12">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <AiOutlineSearch className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  className="block w-full rounded-sm border border-gray-300 py-2 pl-10 text-sm text-gray-800 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0"
                  type="text"
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder="e.g. L-WQST50"
                />
              </div>
              <div className="my-auto w-2/12 ">
                <select
                  value={filterValue}
                  onChange={handleFilter}
                  className="block w-full cursor-pointer rounded-sm border border-gray-300 py-1  text-gray-800 focus:border-gray-400 focus:outline-none focus:ring-0"
                  disabled={loading}
                >
                  <option value="">All</option>
                  <option value="my_loads">My Loads</option>
                  <option value="has_claim">Has Claim</option>
                  <option value="no_claim">No Claim</option>
                  <option value="closed_loads">Closed Loads</option>
                </select>
              </div>
            </div>
          </div>

          {loading ? (
            <div className={'f-col-center-center mt-60 h-full w-full '}>
              <ESpinner isVisible={true} size={30} />
              <span>Loading...</span>
            </div>
          ) : filteredResults.length > 0 ? (
            filteredResults.map((item) => (
              <div className="px-2 lg:px-10" key={item.id}>
                <Instance
                  id={item.id}
                  name={item.name}
                  status={item.status}
                  shipper={item.shipper}
                  pick_up_date={item.pick_up_date}
                  pick_up_location={item.pick_up_location}
                  consignee={item.consignee}
                  delivery_date={item.delivery_date}
                  delivery_time={item.delivery_time}
                  pick_up_time={item.pick_up_time}
                  destination={item.destination}
                  dispatcher={item.dispatcher}
                  customer={item.customer}
                  carrier={item.carrier}
                  isUnderInsured={item.is_under_insured}
                  page={page}
                  setPage={setPage}
                  has_claim={item.has_claim}
                  is_closed={item.is_closed}
                />
              </div>
            ))
          ) : (
            <div className={' flex  flex-col items-center justify-center   py-40'}>
              <img
                src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
                alt={'empty_state'}
                width={200}
                className={''}
              />
              <span className={'mt-5 font-semibold text-gray-700'}>
                {' '}
                No Search or Filter Results Found
              </span>
            </div>
          )}

          {filteredResults.length !== 0 && !loading && (
            <div className={`${loading ? 'hidden' : ' my-10 flex justify-center'}`}>
              <div>
                <CustomPagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ListLoad
