import FCard from '../../../../components/FCard'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { MdOutlineDateRange } from 'react-icons/md'
import LoadMap from './LoadMap'
import GoogleMapsWrapper from '../../../../components/GoogleMapsWrapper'
import { constructAddress } from '../../utils'

const PickDropCard = ({ response }) => {

  const pickUpLocation = constructAddress({ ...response?.pick_up_location })
  const dropOffLocation = constructAddress({ ...response?.destination })

  return (
    <FCard className={' my-2'}>
      <FCard.Header>
        <span className="font-semibold text-gray-800">
          Pick-up / Drop-off information
        </span>
      </FCard.Header>
      <FCard.Body>
        <div className={'flex flex-col'}>
          <div className={'flex items-center gap-2 '}>
            <div className=" flex flex-col gap-20">
              <div className={' font-semibold leading-3'}>Pick-up</div>
              <div className={'whitespace-nowrap font-semibold leading-3'}>Drop-off</div>
            </div>
            <div className=" ml-1 mt-1 flex flex-col  ">
              <div className={' mx-auto h-3 w-3 rounded-full bg-green-700'} />
              <div className={'mx-auto h-20 border-l  border-gray-400 '} />
              <div className={'mx-auto h-3 w-3 rounded-full bg-red-600'} />
            </div>
            <div className=" flex flex-col gap-9">
              <div
                className={' flex flex-col gap-1 text-base font-semibold text-gray-700'}
              >
                <div className=" flex gap-1">
                  <HiOutlineLocationMarker size={18} />
                  {pickUpLocation}
                </div>
                <div className="flex gap-1 font-normal text-gray-500">
                  <MdOutlineDateRange size={18} />
                  {response?.pick_up_date} at {response?.pick_up_time||'N/A'}
                </div>
              </div>
              <div
                className={'flex flex-col gap-1 text-base font-semibold text-gray-700'}
              >
                <div className=" flex gap-1">
                  <HiOutlineLocationMarker size={18} />
                  {dropOffLocation}
                </div>
                <div className="flex gap-1 font-normal text-gray-500">

                  <MdOutlineDateRange size={18} />
                  {response?.delivery_date} at {response?.delivery_time || 'N/A'}
                </div>
              </div>
            </div>
          </div>
          <div className={'flex items-center gap-2 my-5'}>
            {' '}
            <GoogleMapsWrapper>
              <LoadMap dropOff={dropOffLocation} pickUp={pickUpLocation} deliveryDate={response?.delivery_date} />
            </GoogleMapsWrapper>
          </div>
        </div>
      </FCard.Body>
    </FCard>
  )
}
export default PickDropCard
