import { useParams, Link } from 'react-router-dom'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import ESpinner from '../../components/ESpinner'
import { useCustomAxios } from '../../Hooks/useAxios'
import FIconWrapper from '../../components/FIconWrapper'
import { GoChevronLeft } from 'react-icons/go'
import FCard from '../../components/FCard'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { useEffect, useState, useContext } from 'react'
import { axiosInstance } from '../../axios/requister'
import Instance from '../Load/Instance'
import AddAdmin from './AddAdmin'
import FAvatar from '../../components/FAvatar'
import FButton from '../../components/FButton'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
// import emptyState from '../../assets/images/emptyState.svg'
import { UserContext } from '../../components/UserContext'

const ShipmentDetails = () => {
  const { id } = useParams()
  const [loads, setLoads] = useState([])
  const [admins, setAdmins] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const getNextPage = () => setPage(page + 1)
  const getPreviousPage = () => setPage(page - 1)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(UserContext)
  const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false)

  const { response, loading, Refetch } = useCustomAxios({
    url:
      user.selected_role === 'manager' ? `/manager/shipment/${id}/` : `/shipment/${id}/`,

    method: 'GET',
  })

  useEffect(() => {
    setIsLoading(true)
    let filterLoadEndpoint
    if (user.selected_role === 'manager') {
      filterLoadEndpoint = `/manager/filter-load/?page=${page}`
    } else {
      filterLoadEndpoint = `/shipment/filter-load/?page=${page}`
    }

    axiosInstance
      .post(filterLoadEndpoint, { shipment: id })
      .then((res) => {
        setLoads(res.data.results)
        setCount(res.data.count)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [response, page])
  useEffect(() => {
    let adminEndpoint
    if (user.selected_role === 'manager') {
      adminEndpoint = `/manager/admin/?id=${id}`
    } else {
      adminEndpoint = `/shipment/admin/?id=${id}`
    }

    axiosInstance
      .get(adminEndpoint)
      .then((res) => {
        setAdmins(res.data.results)
      })

      .catch(() => {})
  }, [response])

  const totalNumberOfPages = Math.ceil(count / 5)

  if (loading) {
    return (
      <div className={'f-col-center-center h-full w-full'}>
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  }
  return (
    <div>
      <AddAdmin
        isCreateAdminModalOpen={isCreateAdminModalOpen}
        setIsCreateAdminModalOpen={setIsCreateAdminModalOpen}
        callback={() => {
          Refetch()
        }}
        admins={admins}
      />
      <div
        className={
          'sticky top-0 z-20  flex w-full  justify-between border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <FIconWrapper>
          <Link
            to={'/shipment'}
            className={'hove:border rounded p-0.5 hover:bg-gray-100'}
          >
            <GoChevronLeft size={25} />
          </Link>
          <span className={''}>Shipment information</span>
        </FIconWrapper>
      </div>

      <div className=" w-full grid-flow-col grid-cols-3 overflow-x-hidden px-10   pt-10 lg:grid   ">
        <div className="col-span-1   ">
          <span className="flex  whitespace-nowrap text-3xl font-semibold text-gray-800 ">
            {response?.name + ' ' + 'shipment'}
          </span>
          <span>
            <div className="whitespace-nowrap pt-3 text-gray-500">
              {'Created by : '}
              <span>
                {' '}
                {response?.created_by === user?.username ? 'You' : response?.created_by}
              </span>
            </div>
          </span>
        </div>

        <div className=" col-span-2 w-full  ">
          <div>
            <div className=" flex  pb-4 text-gray-800">
              <span className="p-3 text-base font-semibold ">ADMINS</span>
              <IoIosAddCircleOutline
                onClick={() => setIsCreateAdminModalOpen(true)}
                className={`${
                  response?.created_by === user?.username
                    ? 'my-auto cursor-pointer hover:text-primary-500'
                    : 'hidden'
                }`}
                size={25}
              />
            </div>
          </div>

          <div className=" z-0 mr-0 ">
            <div className="  ">
              <div className="  menu-wrap-x-show flex w-11/12  gap-5   ">
                {admins?.map((item) => (
                  <div
                    className="boder flex rounded-md border-2 border-gray-300 p-2 "
                    key={item.id}
                  >
                    <div className="flex flex-col  gap-2   ">
                      <div className="flex">
                        <span>
                          <FAvatar
                            width={25}
                            name={item.admin.username}
                            className="mt-1 mr-2 rounded-lg"
                          />
                        </span>
                        <span className="mt-1 text-gray-900">{item.admin.username}</span>
                      </div>

                      <span className="whitespace-nowrap text-sm text-primary-500">
                        {item.admin.user_type}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-6 w-full  border-gray-300" />
      <div className="px-8 pt-4">
        <div className=" my-10  ">
          <FCard className={`  bg-white `}>
            <FCard.Header className="sticky top-0 z-10">
              <div className="flex justify-between text-gray-900">
                <span className="font-semibold">LOADS</span>
              </div>
            </FCard.Header>
            <FCard.Body className="">
              {isLoading ? (
                <div className={'f-col-center-center h-full w-full py-5'}>
                  <ESpinner isVisible={true} size={25} />
                </div>
              ) : (
                loads?.map((load) => (
                  <div key={load.id}>
                    <Instance
                      id={load.id}
                      name={load.name}
                      status={load.status}
                      shipper={load.shipper}
                      pick_up_date={load.pick_up_date}
                      pick_up_location={load.pick_up_location}
                      consignee={load.consignee}
                      delivery_date={load.delivery_date}
                      delivery_time={load.delivery_time}
                      pick_up_time={load.pick_up_time}
                      destination={load.destination}
                      dispatcher={load.dispatcher}
                    />
                  </div>
                ))
              )}

              {(isLoading || loads?.length === 0) && (
                <div className={' flex  flex-col items-center justify-center   py-10'}>
                  <img
                    src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
                    alt={'empty_state'}
                    width={200}
                    className={''}
                  />
                  <span className={'mt-5 font-semibold text-gray-700'}>
                    No Loads Found In This Shipment
                  </span>
                  {user.selected_role !== 'manager' && (
                    <div>
                      {' '}
                      <span className={'mt-1 text-sm text-gray-600 '}>
                        if you want to add loads to this shipment click on the button
                        below
                      </span>
                      <Link to={'/load/create'}>
                        <div className=" mt-5 flex justify-center">
                          <FButton>
                            <AiOutlinePlusCircle size={20} />
                            <span className={' flex pl-1 text-sm font-medium'}>
                              Create Load
                            </span>{' '}
                          </FButton>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${
                  isLoading || loads?.length === 0
                    ? ' hidden '
                    : 'flex flex-col items-center justify-between rounded-b  py-5 lg:flex-row-reverse'
                }`}
              >
                <div className={'flex items-center justify-between rounded-b  px-5 '}>
                  <div className={'mt-1 flex flex-row-reverse gap-2  '}>
                    <FButton
                      disabled={parseInt(page) === parseInt(totalNumberOfPages)}
                      onClick={() => getNextPage()}
                    >
                      <FIconWrapper>
                        <span>Next</span>
                        <HiChevronRight />
                      </FIconWrapper>
                    </FButton>
                    <FButton
                      onClick={() => getPreviousPage()}
                      disabled={parseInt(page) === 1}
                    >
                      <FIconWrapper>
                        <HiChevronLeft />
                        <span>Previous</span>
                      </FIconWrapper>
                    </FButton>
                  </div>
                </div>
                <span
                  className={`${isLoading ? ' hidden' : ' px-5 pt-5 text-sm lg:pt-0'}`}
                >{`page ${page} from ${totalNumberOfPages}`}</span>
              </div>
            </FCard.Body>
          </FCard>
        </div>
      </div>
    </div>
  )
}
export default ShipmentDetails
