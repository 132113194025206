import React from 'react'
import { useEffect } from 'react'
import { TbTruck, TbUser } from 'react-icons/tb'
import { BsTelephone } from 'react-icons/bs'
import Status from './Status'
import {  HiPrinter } from 'react-icons/hi'
import FButton from './FButton'
import { useCustomAxios } from '../Hooks/useAxios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import ESpinner from './ESpinner'
import { RiBillLine } from "react-icons/ri"
// import { UserContext } from './UserContext'

const BillDoc = () => {
    const [searchParams] = useSearchParams()
  const LoadId = searchParams.get('load')
  const navigate = useNavigate()
//   const { user } = useContext(UserContext)
    useEffect(() => {
        if (!localStorage.getItem('access')) {
          window.location.href = '/login'
        }
      }, [])
    
      const { response, loading } = useCustomAxios({
        url: `/shipment/load/billings/?load_id=${LoadId}`,
        method: 'GET',
      })

      console.log('NEWwwww BILL', response)
    
      const handlePrint = () => {
        window.print()
      }
      function closeTab() {
        window.close()
      }
      const generatedAt = response?.bills?.bill?.created_at
      const formattedDate = new Date(generatedAt).toLocaleDateString('en-US', {
        //i want to display date in this format: Tue Nov 22, 2016 12:58pm MST (i.e. Tue Nov 22, 2016 12:58pm MST)
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })
      if (loading) {
        return (
          <div className={'f-col-center-center h-full w-full'}>
            <ESpinner isVisible={true} size={30} />
            <span className="mt-5">Loading...</span>
          </div>
        )
      } else if (response === undefined) {
        navigate('/404')
      } else
    return (
        <div className="z-0 h-full overflow-auto w-full bg-gray-200">
        <div className="relative py-5 z-10 mx-auto my-5 w-a4 border bg-white pb-2 shadow-lg  print:border-0 print:shadow-none">
          <HiPrinter
            onClick={handlePrint}
            size={25}
            className="absolute lg:right-0 top-0 m-3 transform cursor-pointer transition  duration-300 hover:scale-110 print:hidden "
            style={{ color: '#D86407' }}
          />

          <div className="mt-5 lg:mt-0 px-5">
            <div className="flex flex-col  lg:text-center">
              
              <span className=" text-xl font-semibold  text-gray-700 ">


                {response?.doc_type === 'customer_docs' ? 'Customer Bill' : 'Carrier Bill'}
                 
              </span>
              <span className="text-sm text-gray-500">{formattedDate}</span>
            </div>

            {/* ----------------------------------------LOAD----------------------------------------------------------- */}

            <div className="mt-5 flex text-gray-700">
              <div className="flex gap-1 border p-1">
                <TbTruck size={27} />
                <span className=" text-xl font-semibold ">
                  Load {response?.bills?.bill?.load?.name}
                </span>
              </div>
            </div>
            {/* ----------------------------------------Basic Info----------------------------------------------------------- */}

            <div className="mt-1 flex items-center text-gray-700">
              <div className=" flex  gap-1">
                <TbUser size={27} />
                <span className="text-xl font-semibold">Basic Info</span>
              </div>
            </div>
            <div className="lg:flex text-sm">
              <div className="w-1/2 p-2 ">
                <h3 className="font-inter text-sm text-gray-400 ">Dates</h3>
                <p className=" mt-2 flex gap-1 text-lg ">
                  
                    <div>
                      Bill date :
                    </div>
                 
                  {response?.bills?.bill?.bill_date}
                </p>
                <div className="lg:flex lg:gap-6">
                <p className=" mt-2 flex gap-1 text-lg ">
                  
                  <div>
                    Due date :
                  </div>
               
                {response?.bills?.bill?.due_date}
              </p>
                </div>
                
              </div>
              <div className="w-1/3 p-2 ">
                <h3 className="font-inter text-sm text-gray-400 ">Bill Number</h3>
                <p className=" mt-2 flex gap-1 text-lg">
                 
                  {response?.bills?.bill?.bill_number}
                </p>
                
              </div>
              <div className="w-1/3 p-2 ">
                <h3 className="font-inter text-sm text-gray-400 ">Status</h3>
                <p className=" mt-2 flex gap-1 text-lg ">
                  <Status   statusName={response?.bills?.status} /></p>
                  
              </div>
            </div>
            <hr className="" />

            {/* ----------------------------------------Customer----------------------------------------------------------- */}
            <div className="mt-1 flex items-center text-gray-700">
              <div className=" flex  gap-1">
                <TbUser size={27} />
                <span className="text-xl font-semibold">Customer</span>
              </div>
            </div>

            <div className="flex  text-sm ">
              <div className=" w-1/2 p-2">
                <h3 className="font-inter text-gray-400 ">Contact</h3>
                <div className='lg:grid lg:grid-cols-3 w-full gap-60'>

                <p className=" mt-2 flex gap-1 text-lg">
                  <Tooltip title={'Customer username'} placement={'top'} arrow>
                    <div>
                      <TbUser style={{ color: '#D86407' }} size={24} />
                    </div>
                  </Tooltip>
                  {response?.bills?.bill?.customer?.username}
                </p>
                <p className=" mt-2 flex gap-1 text-lg">
                  <Tooltip title={'Telephone'} placement={'top'} arrow>
                    <div>
                      <BsTelephone style={{ color: '#D86407' }} size={22} />
                    </div>
                  </Tooltip>
                  {response?.bills?.bill?.customer?.phone_number}
                </p>

                <p className=" mt-2 flex gap-1 whitespace-nowrap text-lg">
                  <span>
                    <Tooltip title={'Bill type'} placement={'top'} arrow>
                      <div>
                        <RiBillLine style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                  </span>
                  {response?.bills?.bill?.customer?.billing_entity}
                </p>
              </div>

              </div>
              
            </div>
            <hr className="" />
            {/* ----------------------------------------VENDOR----------------------------------------------------------- */}

            <div className="mt-1 flex text-gray-700">
              <div className=" flex gap-1">
                <TbUser size={27} />
                <span className="text-xl font-semibold">Vendor</span>
              </div>
            </div>

            <div className="flex text-sm ">
            <div className="  w-1/2 p-2">
                <h3 className="font-inter text-gray-400 ">Contact</h3>
                <div className='lg:grid lg:grid-cols-3 w-full gap-60'>
                <p className=" mt-2 flex gap-1 w-1/3 text-lg">
                  <Tooltip title={'Vendor username'} placement={'top'} arrow>
                    <div>
                      <TbUser style={{ color: '#D86407' }} size={24} />
                    </div>
                  </Tooltip>
                  {response?.bills?.bill?.vendor?.username}
                </p> 
                <p className=" mt-2 flex gap-1 w-1/3 text-lg">
                  <Tooltip title={'Telephone'} placement={'top'} arrow>
                    <div>
                      <BsTelephone style={{ color: '#D86407' }} size={22} />
                    </div>
                  </Tooltip>
                  {response?.bills?.bill?.vendor?.phone_number}
                </p>

                {/* <p className=" mt-2 flex gap-1 whitespace-nowrap w-1/3 text-lg">
                  <span>
                    <Tooltip title={'Bill type'} placement={'top'} arrow>
                      <div>
                        <RiBillLine style={{ color: '#D86407' }} size={22} />
                      </div>
                    </Tooltip>
                  </span>
                  {response?.bills?.bill?.vendor?.billing_entity}
                </p> */}
                </div>
              </div>
              
            </div>
            
          </div>
           
          <div className="  my-2 flex justify-center gap-20 print:hidden">
            {/* <FButton btnType={'success'}>I AGREE</FButton> */}
            <FButton btnType={'secondary'} onClick={closeTab}>
              CANCEL
            </FButton>
          </div>
        </div>
      </div>
    )
}

export default BillDoc