import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../../../axios/requister'
import CompanyInfoSection from '../../Company/CompanyInfoSection'
import CreateIndividualBillingForm from './CreateIndividualBillingForm'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { UserContext } from '../../../components/UserContext'
import FModal from '../../../components/FModal'
 import BillingHistory from './BillingHistory'
import BillingInfo from './BillingInfo'

const Billing = ({ taxInfo }) => {
  const { user } = useContext(UserContext)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  // GET company user endpoint
  const { data, status: companyStatus } = useQuery(
    ['company'],
    async () => {
      const response = await axiosInstance.get(`authentication/company/`)
      return response.data
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // Optional: Cache the data for 10 minutes before garbage collection
      keepPreviousData: true, // Keep the previous data while fetching new data
      retry: false,
    }
  )
  // GET billing details endpoint
  const {
    data: billing,
    status: billingStatus,
    refetch,
  } = useQuery(
    ['billing'],
    async () => {
      const response = await axiosInstance.get(`shipment/billing-details/`)
      return response.data
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // Optional: Cache the data for 10 minutes before garbage collection
      keepPreviousData: true, // Keep the previous data while fetching new data
      retry: false,
    }
  )
  
 
  const phoneRegExp = /^(\+?(1|52)[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/

  // Set initial values for Formik form when billing data is available 
  useEffect(() => {
    if (billing) {
      Formik.setValues({
        name_to_print_on_checks: billing[0].name_to_print_on_checks || '',
        email: billing[0].email || '',
        phone_number: billing[0].phone_number || '',
        address: billing[0].address || '',
        city: billing[0].city || '',
        state: billing[0].state || '',
        zip_code: billing[0].zip_code || '',
        country: billing[0].country || '',
        website: billing[0].website || '',
        fax_number: billing[0].fax_number || '',
      })
      setIsUpdate(true)
    } else {
      setIsUpdate(false)
    }
  }, [billing, openModal])

  //this is for individual billing form submission
  const Formik = useFormik({
    initialValues: {
      name_to_print_on_checks: '',
      email: '',
      phone_number: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      fax_number: '',
      website: '',
    },
    validationSchema: Yup.object({
      name_to_print_on_checks: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone_number: Yup.string().required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip_code: Yup.string().required('Zip code is required'),
      country: Yup.string().required('Country is required'),
      website: Yup.string().url('Invalid URL'),
    }),
    onSubmit: (values) => {
      setButtonLoading(true)
      const request = isUpdate
        ? axiosInstance.patch(`shipment/billing-details/${billing[0].id}/`, values)
        : axiosInstance.post('shipment/billing-details/', {
            user_display_name: user?.username,
            first_name: user?.first_name,
            last_name: user?.last_name,
            ...values,
          })

      request
        .then(() => {
          setButtonLoading(false)
          toast.success(`Billing entity ${isUpdate ? 'updated' : 'created'} successfully`)
          setOpenModal(false)
          refetch()
        })
        .catch(() => {
          setButtonLoading(false)
          toast.error(`Failed to ${isUpdate ? 'update' : 'create'} billing entity`)
        })
    },
  })
  //this is for company billing form submission
  const handleSubmitCompanyUser = () => {
    setButtonLoading(true)
    axiosInstance
      .post(`shipment/billing-details/`, {
        company_name: data?.name,
        user_display_name: user?.username,
        first_name: user?.first_name,
        last_name: user?.last_name,
        name_to_print_on_checks: data?.name,
        email: user?.email,
        phone_number: data?.phone_number,
        address: data?.address.address,
        city: data?.address.city,
        state: data?.address.state,
        zip_code: data?.address.zip_code,
        country: data?.address.country,
        website: data?.website || '',
        fax_number: data?.fax_number || '',
      })
      .then(() => {
        setButtonLoading(false)
        toast.success('Billing entity created successfully')
        refetch()
      })
      .catch(() => {
        setButtonLoading(false)
        toast.error('Failed to create billing entity')
      })
  }
  


  if (billingStatus === 'loading' || companyStatus === 'loading') {
    return (
      <div className="f-col-center-center mt-60 h-full w-full">
        <ESpinner isVisible={true} size={30} />
        <span className="mt-5">Loading...</span>
      </div>
    )
  } else if (billing)
    return (
      <div className="m-10 gap-4 lg:flex ">
        <FModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          title={'Edit your billing information'}
        >
          <CreateIndividualBillingForm Formik={Formik} />
          <div className="my-5  flex  flex-row-reverse">
            <FButton className={' w-fit gap-10 '}
              onClick={() => {
              Formik.handleSubmit()
                
              }
              }
            >
              <FIconWrapper>
                <span className={'flex gap-2 text-sm font-medium'}>
                  Save Changes
                  <ESpinner isVisible={buttonLoading} />
                </span>
              </FIconWrapper>
            </FButton>
          </div>
        </FModal>
        <div className="lg:w-8/12">
          <div className="flex flex-col ">
            <span className=" text-xl font-bold text-gray-700">
              Billing History
              <br />{' '}
              <span className="mt-2 flex text-base font-normal text-gray-500">
                Billing information and view recipes here.
              </span>
            </span>
          </div>
          <span className="my-3 flex h-auto w-full flex-col gap-5 bg-white  font-bold">
            <BillingHistory />
          </span>
        </div>

        <div className="lg:w-4/12">
          <div className="flex flex-col ">
            <span className=" text-xl font-bold text-gray-700">
              Billing Information
              <br />{' '}
              <span className="mt-2 flex text-base font-normal text-gray-500">
                Manage your billing information.
              </span>
            </span>
          </div>

          {/* Billing Information */}
          <BillingInfo taxInfo={taxInfo} 
          billing={billing} 
          setOpenModal={setOpenModal}
          />
          
        </div>
      </div>
    )
  else {
    return (
      <div className="mx-2 my-5 flex flex-col  p-4 sm:mx-8 sm:my-8 md:mx-20 md:my-5  ">
        {taxInfo?.data?.type === 'company' ? (
          <div className="my-3 flex h-auto w-full flex-col gap-5 bg-white p-5">
            <div className="mb- flex flex-col">
              <div className="text-xl font-bold text-gray-700">Your Billing Details</div>
              <span className="text-sm font-normal text-gray-500">
                {`You are under a ${data?.name} company. The billing details will be saved under
            your company.`}
              </span>
            </div>
            <div className="flex flex-col gap-4 pb-7">
              <CompanyInfoSection data={data} />
            </div>
          </div>
        ) : (
          <div className="my-3 flex h-auto w-full flex-col gap-5 bg-white p-5">
            <div className="mb- flex flex-col">
              <div className="text-xl font-bold text-gray-700">
                Create Individual Billing
              </div>
              <span className="text-sm font-normal text-gray-500">
                {`You can create an individual billing entity. This will be saved under
            your name.`}
              </span>
            </div>
            <div className="flex flex-col gap-4 pb-7">
              <CreateIndividualBillingForm Formik={Formik} />
            </div>
          </div>
        )}

        <div className="my-5  flex  flex-row-reverse">
          <FButton
            className={' w-fit gap-10 '}
            onClick={() => {
              if (taxInfo?.data?.type === 'individual') {
                Formik.handleSubmit()
              } else {
                handleSubmitCompanyUser()
              }
            }}
          >
            <FIconWrapper>
              <span className={'flex gap-2 text-sm font-medium'}>
                Create Billing Entity
                <ESpinner isVisible={buttonLoading} />
              </span>
            </FIconWrapper>
          </FButton>
        </div>
      </div>
    )
  }
}

export default Billing
